import {inject, Injectable} from '@angular/core';
import {DeviceDetectorService} from "ngx-device-detector";

@Injectable({
  providedIn: 'root'
})
export class DeviceInfoService {

  public deviceDetectorService: DeviceDetectorService = inject(DeviceDetectorService);
  public currentDevice: 'ios' | 'android' | 'desktop' | 'huawei';


  constructor() {
    this.currentDevice = this.detectDevice();
  }

  private detectDevice(): 'ios' | 'android' | 'desktop' | 'huawei' {
    let currentDevice;
    if(this.deviceDetectorService.isDesktop()) {
      currentDevice = 'desktop';
    } else if(this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet()) {
      if(this.deviceDetectorService.os === 'iOS') {
        currentDevice = 'ios';
      } else if(this.deviceDetectorService.os === 'Android') {
        currentDevice = 'android';
      } else {
        currentDevice = 'huawei';
      }
    }
    return currentDevice;
  }


}
