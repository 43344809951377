import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {IPayloadResponseOrder} from "./websocket/websoket.interface";

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor() { }

  public currentOrder$: BehaviorSubject<IPayloadResponseOrder> = new BehaviorSubject<IPayloadResponseOrder>(null);
  public passengerLocation$: BehaviorSubject<string> = new BehaviorSubject<any>(null);
  public driverLocation$: BehaviorSubject<string> = new BehaviorSubject<any>(null);
  public expansionOpened$: BehaviorSubject<'opened' | 'closed'> = new BehaviorSubject<'opened' | 'closed'>(null);
  public arrivingTimer$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

}
