@if (orderService.currentOrder$ | async; as order) {
  <div class="drawer-info-container background-sub" id="drawer">
    <div (click)="toggleExpansion()" class="shevron-container">
      <div class="shevron-button">
        @if (orderService.expansionOpened$ | async; as exp) {
          <mat-icon class="expand-color">{{exp === 'opened' ? 'expand_more' : 'expand_less'}}</mat-icon>
        }
      </div>
    </div>
    <div class="main-content">
      <div class="always-view">
        <div class="status-timer drawer-info-main-text">
            <div class="status">
              @if (order.trip_status === 'arriving' || order.trip_status === 'accepted') {
                {{'STATUS.ARRIVING' | translate}}: &nbsp;
              } @else if (order.trip_status === 'waiting' || order.trip_status === 'waiting_paused') {
                {{'STATUS.CAR_SUBMITTED' | translate}}
              } @else if (order.trip_status === 'in_progress' || order.trip_status === 'paused') {
                {{'STATUS.IN_PROGRESS' | translate}}: &nbsp;
              }
            </div>
            @if (order.trip_status === 'arriving' || order.trip_status === 'accepted') {
              <div class="timer warning">{{orderService.arrivingTimer$ | async | timeRemaining}}</div>
            } @else if (order.trip_status === 'in_progress' || order.trip_status === 'paused') {
              <div class="timer warning">~{{(dateNow + ((orderService.arrivingTimer$ | async) * 1000)) | date: 'HH:mm'}}</div>
            }
        </div>
        @if (order.is_next) {
          <div class="previous-order drawer-info-driver-previous-order-color">{{'OTHER.DRIVER_COMPLETE_PREVIOUS_ORDER' | translate}}</div>
        }
        <div (click)="copyCarInfo(order)" class="vehicle-number drawer-info-standard-text">{{order.number}}</div>
        <div (click)="copyCarInfo(order)" class="vehicle-mark-model-color drawer-info-standard-text">{{order.brand}} {{order.model}}  -  {{('color.' + order.color) | uppercase | translate}}</div>
      </div>
      <mat-divider class="drawer-info-divider drawer-info-driver-divider-color"></mat-divider>
      <div class="driver-info">
        <div class="driver-info-block">
          <div class="driver-photo">
            {{order.first_name | slice: 0: 1 | uppercase}}
            <img #image [hidden]="image.hidden" (error)="image.hidden = true" [src]="'https://public.utaxcloud.net/' + (isProd ? '' : 'dev/') + 'upload/drivers/avatar/' + order.driver_uuid +'/avatar.jpg'"/>
          </div>
          <div class="driver-name-rating">
            <div class="driver-name drawer-info-driver-name-text">{{order.first_name}}</div>
            <div class="driver-rating">
              <mat-icon class="star-icon">star</mat-icon>
              {{(!order.driver_rating || order.driver_rating < 4 ? 5 : order.driver_rating) | number: '1.1'}}
            </div>
          </div>
        </div>
        <div (click)="callTo(order.driver_phone)" class="connect-pseudo-button drawer-info-driver-name-text drawer-info-driver-button-border-color">
          @if (systemThemeDetectService.theme === 'dark') {
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M2.86444 4.05996C3.11486 3.62265 4.49546 2.03517 5.47927 2.08309C5.77361 2.10962 6.03379 2.29618 6.24519 2.51355H6.246C6.73059 3.01333 8.12094 4.89949 8.199 5.29657C8.3917 6.27045 7.28836 6.83185 7.62578 7.81344C8.48601 10.0291 9.96824 11.5892 12.0741 12.4937C13.0059 12.8497 13.5393 11.6893 14.4645 11.8913C14.8418 11.9743 16.6346 13.4368 17.1086 13.9477V13.9477C17.3143 14.1694 17.4932 14.4441 17.5176 14.7539C17.5542 15.845 15.9524 17.3178 15.6402 17.5061C14.9036 18.0615 13.9425 18.0512 12.7709 17.4778C9.50154 16.0461 4.27674 10.6504 2.89127 7.07917C2.36114 5.85283 2.32537 4.83445 2.86444 4.05996Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.0312 2.28906C14.962 2.63156 17.276 5.0649 17.6054 8.14906" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.0312 5.24219C13.4325 5.52969 14.5274 6.68219 14.8005 8.15719" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          } @else {
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M2.86444 4.05996C3.11486 3.62265 4.49546 2.03517 5.47927 2.08309C5.77361 2.10962 6.03379 2.29618 6.24519 2.51355H6.246C6.73059 3.01333 8.12094 4.89949 8.199 5.29657C8.3917 6.27045 7.28836 6.83185 7.62578 7.81344C8.48601 10.0291 9.96824 11.5892 12.0741 12.4937C13.0059 12.8497 13.5393 11.6893 14.4645 11.8913C14.8418 11.9743 16.6346 13.4368 17.1086 13.9477V13.9477C17.3143 14.1694 17.4932 14.4441 17.5176 14.7539C17.5542 15.845 15.9524 17.3178 15.6402 17.5061C14.9036 18.0615 13.9425 18.0512 12.7709 17.4778C9.50154 16.0461 4.27674 10.6504 2.89127 7.07917C2.36114 5.85283 2.32537 4.83445 2.86444 4.05996Z" stroke="#4D535F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.0312 2.28906C14.962 2.63156 17.276 5.0649 17.6054 8.14906" stroke="#4D535F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.0312 5.24219C13.4325 5.52969 14.5274 6.68219 14.8005 8.15719" stroke="#4D535F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          }
          {{'OTHER.GET_IN_TOUCH' | translate}}
        </div>
      </div>
      <mat-divider class="drawer-info-divider drawer-info-driver-divider-color"></mat-divider>
      <div class="point-list">
        <ul class="note-container main-text-color">
          @for (address of order.addresses; track address) {
            <li [ngClass]="'note note-' + systemThemeDetectService.theme">{{address.name}}</li>
          }
        </ul>
      </div>
    </div>
    <div class="bottom-static-info logo-block-shadow background-sub">
      <svg (click)="openLink('https://taxi838.ua/ua/')" style="cursor: pointer" width="90" height="20" viewBox="0 0 90 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_19972_549)">
          <path d="M59.2154 9.32173C59.9478 8.83558 60.521 8.24754 60.9244 7.56396C61.3765 6.79759 61.6058 5.93569 61.6037 5.00373C61.6016 3.68965 61.1048 2.46685 60.2089 1.56249C59.1942 0.534999 57.757 -0.00422094 56.048 2.48812e-05C54.4261 0.0042707 52.9443 0.539244 51.8744 1.50729C51.3394 1.99132 50.9233 2.56875 50.6367 3.22048C50.3438 3.8892 50.1952 4.6216 50.1973 5.39434C50.1973 6.29658 50.4499 7.17334 50.9191 7.9291C51.3161 8.56385 51.8553 9.10944 52.5304 9.55313C51.7024 10.0329 51.038 10.621 50.5476 11.3109C49.8873 12.2365 49.554 13.3255 49.5562 14.5483C49.5604 16.0259 50.1633 17.3867 51.2545 18.3823C51.8171 18.8961 52.49 19.2952 53.2564 19.5711C54.0546 19.8577 54.9377 20.0021 55.8803 20C57.636 19.9957 59.2473 19.4183 60.4149 18.3717C61.0008 17.8473 61.4593 17.2232 61.7735 16.5163C62.0983 15.7881 62.2618 14.992 62.2597 14.1492C62.2575 12.9625 61.9115 11.9032 61.2301 11.0031C60.7248 10.3344 60.0497 9.77179 59.2154 9.31961V9.32173ZM55.9207 2.7492C58.1816 2.74495 58.3004 4.84451 58.3026 5.26485C58.3047 6.52373 57.5426 7.5491 56.1988 8.09893C54.3179 7.52787 53.4729 6.63412 53.4708 5.22026C53.4687 3.99322 54.2244 2.75344 55.9207 2.7492ZM55.927 17.2253C55.1246 17.2253 54.4261 16.9727 53.9039 16.4908C53.3031 15.9346 52.9783 15.1067 53.0144 14.2193V14.2002C53.0123 12.6674 53.94 11.4765 55.5725 10.9097C57.8759 11.64 58.8227 12.6632 58.8269 14.4146C58.8312 16.0917 57.6657 17.2211 55.927 17.2253Z" fill="#FFCC00"/>
          <path d="M72.445 9.41726C72.2688 9.31748 72.0862 9.2262 71.8994 9.14553C72.5511 8.75491 73.0861 8.26877 73.4937 7.69133C74.0754 6.87189 74.3683 5.9187 74.3662 4.85937C74.3641 3.56227 73.8886 2.37344 73.0309 1.50942C72.0501 0.518016 70.6172 -0.00422014 68.8912 2.56841e-05C66.8851 0.00427151 65.0084 0.781257 64.0361 1.54763L63.7771 1.75143L64.7303 4.56429L65.3566 4.08451C66.0211 3.57501 67.2311 2.97635 68.4475 2.97422C70.6872 2.96998 70.9335 4.57278 70.9356 5.26272C70.9398 7.1967 68.9655 7.9015 67.6387 7.90575L65.8024 7.90999L65.8088 10.742L67.6451 10.7377C69.5196 10.7335 71.5343 11.5699 71.564 13.9348C71.5725 14.3658 71.5003 15.4442 70.6426 16.2276C70.0652 16.7562 69.2479 17.0237 68.2161 17.0258C66.6855 17.03 65.2632 16.338 64.7261 15.9707L64.1041 15.5461L63.1509 18.4587L63.4396 18.6604C64.327 19.2803 66.1208 20.0042 68.2501 19.9979C70.3433 19.9936 72.099 19.3631 73.3281 18.1764C74.4426 17.098 75.0562 15.6077 75.0519 13.9773C75.0498 12.6526 74.6188 11.4574 73.8058 10.5233C73.4236 10.0839 72.9672 9.71235 72.4471 9.41514L72.445 9.41726Z" fill="#FFCC00"/>
          <path d="M88.2676 11.0052C87.7623 10.3365 87.0872 9.77391 86.2529 9.32173C86.9853 8.83558 87.5585 8.24754 87.9619 7.56396C88.4141 6.79759 88.6433 5.93569 88.6412 5.00373C88.6391 3.68965 88.1423 2.46685 87.2465 1.56249C86.2317 0.534999 84.7924 -0.00422094 83.0834 2.48812e-05C81.4615 0.0042707 79.9797 0.539244 78.9098 1.50729C78.3748 1.99132 77.9587 2.56875 77.6721 3.22048C77.3792 3.8892 77.2306 4.6216 77.2327 5.39434C77.2327 6.29658 77.4853 7.17334 77.9545 7.9291C78.3515 8.56385 78.8907 9.10944 79.5658 9.55313C78.7378 10.0329 78.0734 10.621 77.583 11.3109C76.9227 12.2365 76.5894 13.3255 76.5916 14.5483C76.5958 16.0259 77.1987 17.3867 78.2899 18.3823C78.8525 18.8961 79.5254 19.2952 80.2918 19.5711C81.09 19.8577 81.9731 20.0021 82.9157 20C84.6714 19.9957 86.2827 19.4183 87.4503 18.3717C88.0362 17.8473 88.4947 17.2232 88.8089 16.5163C89.1337 15.7881 89.2972 14.992 89.2951 14.1492C89.2929 12.9625 88.9469 11.9032 88.2655 11.0031L88.2676 11.0052ZM82.9582 2.7492C85.2191 2.74495 85.338 4.84451 85.3401 5.26485C85.3422 6.52373 84.5801 7.5491 83.2363 8.09893C81.3554 7.52787 80.5105 6.63412 80.5083 5.22026C80.5062 3.99322 81.262 2.75344 82.9582 2.7492ZM82.9645 17.2253C82.1621 17.2253 81.4636 16.9727 80.9414 16.4908C80.3406 15.9346 80.0158 15.1067 80.0519 14.2193V14.2002C80.0498 12.6674 80.9775 11.4765 82.61 10.9097C84.9134 11.64 85.8602 12.6632 85.8644 14.4146C85.8687 16.0917 84.7032 17.2211 82.9645 17.2253Z" fill="#FFCC00"/>
          <path d="M43.493 3.47815H40.6504V16.551H43.493V3.47815Z" fill="#008DFF"/>
          <path d="M37.1447 16.5408H34.1684L31.2748 12.0508L31.2472 12.0084C31.2472 12.0084 29.5234 15.1269 28.6658 16.5556H25.6194C25.9421 16.0164 28.7825 11.5052 29.6911 10.0234L29.7017 10.0065L29.6911 9.98736C29.0012 8.86434 26.0758 4.02834 25.7383 3.4679H28.6509C28.8144 3.72264 30.8821 6.9155 31.4107 7.71797L31.4468 7.77104C31.4468 7.77104 33.6631 3.70778 33.7905 3.48276H36.701L32.901 9.59886L37.1447 16.5408Z" fill="#008DFF"/>
          <path d="M9.86092 3.47815V5.84307H6.34326V16.5489H3.51766V5.84307H0V3.47815H9.86092Z" fill="#008DFF"/>
          <path d="M18.952 3.47815H15.774C15.6148 3.87938 13.4833 9.74074 10.9167 16.551H13.9037C14.133 15.8717 14.4004 14.9673 14.687 13.9972C15.1711 14.0014 19.5485 13.9972 20.0177 14.0035C20.3212 14.9949 20.5951 15.8908 20.8223 16.5532H23.8092C21.3296 9.76621 19.1112 3.87938 18.952 3.48027V3.47815ZM15.377 11.7044C16.1242 9.38834 17.0923 6.53939 17.3258 6.07235C17.5424 6.53514 18.5507 9.41806 19.3001 11.6959C18.8161 11.6981 15.8398 11.7044 15.377 11.7044Z" fill="#008DFF"/>
        </g>
        <defs>
          <clipPath id="clip0_19972_549">
            <rect width="89.2951" height="20" fill="white"/>
          </clipPath>
        </defs>
      </svg>
      @if(deviceInfoService.currentDevice === 'android') {
        <img [src]="'/assets/custom/' + systemThemeDetectService.theme + '/img/' + deviceInfoService.currentDevice + '.svg'"
             (click)="openLink('https://play.google.com/store/apps/details?id=com.mobox.taxi')">
      } @else if(deviceInfoService.currentDevice === 'ios') {
        <img [src]="'/assets/custom/' + systemThemeDetectService.theme + '/img/' + deviceInfoService.currentDevice + '.svg'"
             (click)="openLink('https://apps.apple.com/ua/app/taxi-838-%D0%B7%D0%B0%D0%BC%D0%BE%D0%B2-%D1%82%D0%B0%D0%BA%D1%81%D1%96-%D0%BE%D0%BD%D0%BB%D0%B0%D0%B9%D0%BD/id1148856166')">
      } @else if(deviceInfoService.currentDevice === 'huawei') {
        <img [src]="'/assets/custom/' + systemThemeDetectService.theme + '/img/' + deviceInfoService.currentDevice + '.svg'"
             (click)="openLink('https://appgallery.huawei.com/#/app/C102506291')">
      }
    </div>
  </div>
}
