import {Component, inject, OnInit} from '@angular/core';
import {ActivatedRoute, RouterOutlet} from '@angular/router';
import {MapComponent} from "./components/map/map.component";
import {WebsocketService} from "./services/websocket/websocket.service";
import {LinkNotActiveComponent} from "./components/link-not-active/link-not-active.component";
import {OrderService} from "./services/order.service";
import {TranslateService} from "@ngx-translate/core";
import {Title} from "@angular/platform-browser";


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, MapComponent, LinkNotActiveComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  public webSocketService: WebsocketService = inject(WebsocketService);
  public orderService: OrderService = inject(OrderService);
  private token: string;

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private titleService: Title
  ) {
    this.initTranslateService();
    this.titleService.setTitle('Taxi 838');
  }

  ngOnInit() {
    // setInterval(() => {
    //   const timeElapsed = Date.now();
    //   const today = new Date(timeElapsed);
    //   console.log(today.toISOString());
    // }, 5000);
    this.route.queryParams.subscribe(queryParam => {
      this.token = queryParam['token'];
      if(this.token) {
        this.webSocketService.init(
          this.token
          // 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MjAxODE5OTYsIm5iZiI6MTcyMDE4MTk5NiwiZXhwIjoxNzIwMTg3MTI3LCJpc3MiOiJtaXJhbmRhIiwiYXVkIjoib2N0b2J1cyIsImF1dGhfbWV0aG9kIjoibWlyYW5kYV90b2tlbiIsInJlcXVlc3RfdXVpZCI6ImM5MzQwODQzLTkxZDEtNDE2ZS1hYjkxLTA1YTY0NzZiZTdhZSIsInBob25lIjoiKzM4MDUwMTM3NTc4NyIsInBlcm1pc3Npb25zIjoiYmFzZV93YXRjaGVyIiwidXVpZCI6ImM5MzQwODQzLTkxZDEtNDE2ZS1hYjkxLTA1YTY0NzZiZTdhZSIsInVzZXJfdHlwZSI6IndhdGNoZXIiLCJ1c2VyX2lkIjoxNjQzNTN9.wmURikNYgJPE7NsaQkd-YsLuxxIsGQ8xwEk3h2Ni9o8'
        ).subscribe((message) => {
          if (message?.type === 'connect') {
            this.webSocketService.send({
              message_type: 'get_request_details',
              payload: {
                request_uuid: message?.payload?.user?.uuid
              }
            })
            this.webSocketService.listenChat(message?.payload?.user?.uuid, 'on')
          } else if (message?.type === 'request_details') {
            this.orderService.currentOrder$.next(message?.payload);
          } else if (message?.type === 'updates') {
            if (Array.isArray(message?.payload)) {
              const messages = message.payload.filter(item => {
                if (item.message_type === 'location') {
                  if (item.user.user_type === 'passenger') {
                    this.orderService.passengerLocation$.next(item.message.location);
                  } else if (item.user.user_type === 'driver') {
                    this.orderService.driverLocation$.next(item.message.location);
                  }
                }
              });
            } else if(message?.payload) {
              if(message.payload.message_type === 'trip_updated') {
                this.orderService.currentOrder$.next({...this.orderService.currentOrder$.value, trip_status: message.payload?.message?.payload?.status});
              } else if (message.payload.message_type === 'request_update') {
                if(message.payload.message.payload.status === 'trip_started') {
                  this.orderService.currentOrder$.next({...this.orderService.currentOrder$.value, is_next: false});
                }
              }
            }
          }
        });
      }
    });

    // setTimeout(() => {
    //   this.orderService.currentOrder$.next({...this.orderService.currentOrder$.value, status: 'in_progress'});
    // }, 5000);

  }

  private initTranslateService(): void {
    this.translateService.addLangs(['en', 'uk']);
    this.translateService.setDefaultLang('uk');
    const browserLang = this.translateService.getBrowserLang();
    this.translateService.use(browserLang.match(/en|uk/) ? browserLang : 'uk');
  }



}

// {
//   "chat": {
//   "id": 15587,
//     "request_uuid": "9addde05-ca8d-4958-b512-641d27c1fc5e"
// },
//   "message": {
//   "is_hidden": true,
//     "payload": {
//     "status": "in_progress",
//       "trip_id": "1a826d9c-9b61-4d57-8807-6662ba1264c1",
//       "updated_at": 1721209884
//   }
// },
//   "message_type": "trip_updated",
//   "user": {
//   "id": 1,
//     "name": "Taxi 838",
//     "user_id": 0,
//     "user_type": "bot"
// }
// }
