import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SystemThemeDetectService {

  public theme: 'dark' | 'light' = 'light';

  constructor() {
    this.detectSystemTheme();
  }

  private detectSystemTheme() {
    const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
    this.theme = darkThemeMq.matches ? 'dark' : 'light'
  }



}
