import {CanActivateFn, Router, Routes} from '@angular/router';
import {MapComponent} from "./components/map/map.component";
import {LinkNotActiveComponent} from "./components/link-not-active/link-not-active.component";
import {inject} from "@angular/core";
import {OrderService} from "./services/order.service";
import {WebsocketService} from "./services/websocket/websocket.service";

// @ts-ignore
export const activateGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  // return inject(OrderService).activeOrder$ ? true : inject(Router).navigateByUrl('/error');
  return inject(WebsocketService).errorCounter ? inject(Router).navigateByUrl('/error') : true;
};


export const routes: Routes = [
  {
    path: 'map',
    component: MapComponent,
    // canActivate: [activateGuard]
  },
  {
    path: 'error',
    component: LinkNotActiveComponent
  },
  {
    path: '',
    redirectTo: 'map',
    pathMatch: 'full'
  }
];
