import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from "@angular/material/snack-bar";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-notification',
  standalone: true,
  imports: [
    TranslateModule
  ],
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss'
})
export class NotificationComponent {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }

}
