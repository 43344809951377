import {Component, inject} from '@angular/core';
import {DeviceInfoService} from "../../services/device-info.service";
import {SystemThemeDetectService} from "../../services/system-theme-detect.service";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-link-not-active',
  standalone: true,
  imports: [
    TranslateModule
  ],
  templateUrl: './link-not-active.component.html',
  styleUrl: './link-not-active.component.scss'
})
export class LinkNotActiveComponent {
  public deviceInfoService: DeviceInfoService = inject(DeviceInfoService);
  public systemThemeDetectService: SystemThemeDetectService = inject(SystemThemeDetectService);

  public openLink(url: string) {
    window.open(url, '_blank');
  }

}
