import {Component, DestroyRef, inject, OnDestroy, OnInit} from '@angular/core';
import {MatDivider} from "@angular/material/divider";
import {MatIcon} from "@angular/material/icon";
import {SystemThemeDetectService} from "../../services/system-theme-detect.service";
import {OrderService} from "../../services/order.service";
import {AsyncPipe, DatePipe, DecimalPipe, NgClass, NgOptimizedImage, SlicePipe, UpperCasePipe} from "@angular/common";
import {TimeRemainingPipe} from "../../pipes/time-remaining.pipe";
import {DeviceInfoService} from "../../services/device-info.service";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {tap, timer} from "rxjs";
import {CdkCopyToClipboard, Clipboard} from "@angular/cdk/clipboard";
import {IPayloadResponseOrder} from "../../services/websocket/websoket.interface";
import {HandleEventService} from "../../services/handle-event.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-drawer-info',
  standalone: true,
  imports: [
    MatDivider,
    MatIcon,
    AsyncPipe,
    DatePipe,
    SlicePipe,
    DecimalPipe,
    TimeRemainingPipe,
    UpperCasePipe,
    NgClass,
    TranslateModule,
    NgOptimizedImage,
    CdkCopyToClipboard
  ],
  templateUrl: './drawer-info.component.html',
  styleUrl: './drawer-info.component.scss'
})
export class DrawerInfoComponent implements OnInit, OnDestroy{

  public timeRemaning;

  public systemThemeDetectService: SystemThemeDetectService = inject(SystemThemeDetectService)
  public orderService: OrderService = inject(OrderService);
  public deviceInfoService: DeviceInfoService = inject(DeviceInfoService);
  public translateService: TranslateService = inject(TranslateService);
  public handleEventService: HandleEventService = inject(HandleEventService);
  public clipboardService: Clipboard = inject(Clipboard);
  private destroyRef = inject(DestroyRef);
  public dateNow = Date.now();
  public isProd = environment.production;



  ngOnInit(): void {
    this.checkOrder();
  }




  public callTo(phone: string): void {
    document.location.href="tel:"+phone;
  }

  public openLink(url: string) {
    window.open(url, '_blank');
  }

  public toggleExpansion() {
    this.orderService.expansionOpened$.next(
      !this.orderService.expansionOpened$.value || this.orderService.expansionOpened$.value === 'closed' ? 'opened' : 'closed'
    );
  }

  private checkOrder() {
    timer(0, 1000)
      .pipe(
        tap(() => this.dateNow = Date.now()),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe()
    this.orderService.expansionOpened$
      .pipe(
        tap((el) => {
          if (el === 'closed') {
            document.getElementById('drawer')?.scrollTo(0, 0);
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe()
  }


  ngOnDestroy(): void {
    this.orderService.expansionOpened$.next(null);
  }

  copyCarInfo(order: IPayloadResponseOrder) {
    if(this.deviceInfoService.currentDevice !== 'desktop') {
      this.handleEventService.openSnackBar(this.translateService.instant('NOTIFICATION.INFO_CAR_COPIED'));
      this.clipboardService.copy(`${order.brand} ${order.model} (${this.translateService.instant(`COLOR.${order.color.toUpperCase()}`)}) ${order.number}`);
    }
  }
}
