import { Injectable } from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {NotificationComponent} from "../shared/components/notification/notification.component";

@Injectable({
  providedIn: 'root'
})
export class HandleEventService {

  constructor(
    private snackBar: MatSnackBar,
  ) { }


  openSnackBar(message: any, duractionInSeconds?: number, isError?: boolean) {
    let snackClass = 'snackbar';
    if (isError) {
      snackClass = 'snackbar-error';
    }
    this.snackBar.openFromComponent(NotificationComponent, {
      data: {
        message
      },
      duration: (duractionInSeconds ? duractionInSeconds : 2) * 1000,
      verticalPosition: 'top',
      panelClass: [snackClass]
    });
  }
  closeSnackBar() {
    this.snackBar.dismiss();
  }
}
