import {inject, Inject, Injectable} from '@angular/core';
import {WebSocketSubject, WebSocketSubjectConfig} from "rxjs/internal/observable/dom/WebSocketSubject";
import {IWsMessage, IWsSendMessage, WebSocketConfig} from "./websoket.interface";
import {
  catchError,
  delayWhen,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  Observer, repeat, retry, retryWhen,
  share, skip, skipWhile,
  Subject,
  SubscriptionLike, take,
  takeWhile, tap, throwIfEmpty, timer
} from "rxjs";
import {environment} from "../../../environments/environment";
import * as pako from 'pako';
import moment from "moment";
import {OrderService} from "../order.service";

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  public socketSubject$: WebSocketSubject<any>;
  public wsConfig: WebSocketSubjectConfig<any>;
  public errorCounter = 0;
  public orderService: OrderService = inject(OrderService);

  constructor() {
  }

  public init(token: string): Observable<IWsSendMessage> {
    this.wsConfig = {
      url: environment.octobus + `?token=${token}`,
      binaryType: 'arraybuffer',
      closeObserver: {
        next: (event) => {
        }
      },
      openObserver: {
        next: (event) => {
        }
      },
      serializer: (e) => {
        if (!environment.production) {
          console.log(`%cO%cc%ct%co%cb%cu%cs%c↑%c'(${moment().format('HH:mm:ss:SSS')})`,
            'font-size: 20px; color: blue;',
            'font-size: 20px; color: lightblue;',
            'font-size: 20px; color: lightgreen;',
            'font-size: 20px; color: green',
            'font-size: 20px; color: yellow;',
            'font-size: 20px; color: orange',
            'font-size: 20px; color: red',
            'font-size: 20px; color: green',
            'font-size: 20px; color: purple'
          );
          console.dir(e);
        }
        return pako.gzip(JSON.stringify(e));
      },
      deserializer: (e) => {
          const unpacked = JSON.parse(pako.ungzip(e.data, { to: 'string' }));
          if (!environment.production) {
            console.log(`${'%cO%cc%ct%co%cb%cu%cs%c↓%c'}(${moment().format('HH:mm:ss:SSS')})`,
              'font-size: 20px; color: blue;',
              'font-size: 20px; color: lightblue;',
              'font-size: 20px; color: lightgreen;',
              'font-size: 20px; color: green',
              'font-size: 20px; color: yellow;',
              'font-size: 20px; color: orange',
              'font-size: 20px; color: red',
              'font-size: 20px; color: red',
              'font-size: 20px; color: purple'
            );
            console.log(unpacked);
          }
          return unpacked;
        }
    };
    this.socketSubject$ = new WebSocketSubject(this.wsConfig);
    return this.socketSubject$.pipe(
      tap(() => {
        this.errorCounter = 0;
      }),
      catchError((error) => {
        this.errorCounter +=1
        return error;
      }),
      skipWhile((res) => {
        return res === null
      }),
      throwIfEmpty(),
      retry({count: 60, delay: () => timer(this.errorCounter * 1000), resetOnSuccess: true}),
      );
  }

  public disconnect() {
    if (this.socketSubject$) {
      this.socketSubject$.complete();
    }
  }

  public send(message: IWsSendMessage): void {
    if (this.socketSubject$) {
      this.socketSubject$.next(message);
    } else {
      console.error('Send error!');
    }
  }

  public listenChat(orderId: string, slider: 'on' | 'off' = 'on') {
    const chatConnectionConfig: IWsSendMessage = {
      message_type: `chat:sniff:${slider}`,
      payload: {
        chat_uuid: orderId
      }
    };
    this.send(chatConnectionConfig);
  }

}
