import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  OnInit,
  ViewChild
} from '@angular/core';
import {DrawerInfoComponent} from "../drawer-info/drawer-info.component";
import {DrawerPosition, MtxDrawer} from "@ng-matero/extensions/drawer";
import {BreakpointObserver} from "@angular/cdk/layout";
import L from 'leaflet';
import 'leaflet.gridlayer.googlemutant';
import 'leaflet-routing-machine';
import {SystemThemeDetectService} from "../../services/system-theme-detect.service";
import {DARK_END_ICON, DARK_STYLES_MAP, LIGHT_END_ICON} from "../utils/dark-styles-map";
import {OrderService} from "../../services/order.service";
import {createMarker, createPoly} from "../utils/map";
import {tap} from "rxjs";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {LinkNotActiveComponent} from "../link-not-active/link-not-active.component";
import {WebsocketService} from "../../services/websocket/websocket.service";
import {AsyncPipe} from "@angular/common";

@Component({
  selector: 'app-map',
  standalone: true,
  imports: [
    LinkNotActiveComponent,
    AsyncPipe
  ],
  templateUrl: './map.component.html',
  styleUrl: './map.component.scss'
})
export class MapComponent implements OnInit, AfterViewInit {

  private drawerPosition: DrawerPosition = 'left';

  @ViewChild('map', { static: true }) mapEl: ElementRef;
  private map!: L.Map
  markers: any[];
  passengerMarker;
  driverMarker;
  machine: L.Routing.Control;
  public arrivingStatuses = ['arriving', 'accepted'];
  public waitingStatuses = ['waiting', 'waiting_paused'];
  public progressStatuses = ['in_progress', 'paused'];
  public completedStatuses = ['canceled', 'completed'];



  private drawer: MtxDrawer = inject(MtxDrawer);
  public orderService: OrderService = inject(OrderService);
  public websocketService: WebsocketService = inject(WebsocketService);
  private systemThemeDetectService: SystemThemeDetectService = inject(SystemThemeDetectService)
  private breakpointObserver: BreakpointObserver = inject(BreakpointObserver);
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  private destroyRef: DestroyRef = inject(DestroyRef);

  private carIcon = L.icon({
    iconUrl: `assets/custom/${this.systemThemeDetectService.theme}/img/car.svg`,
    iconAnchor: [26, 20],
  });
  private startIcon = L.icon({
    iconUrl: `assets/custom/${this.systemThemeDetectService.theme}/img/start.svg`,
    iconAnchor: [20, 41],
  });
  private endIcon = L.icon({
    iconUrl: `assets/custom/${this.systemThemeDetectService.theme}/img/end.svg`,
  });
  private waypointIcon = L.icon({
    iconUrl: `assets/custom/${this.systemThemeDetectService.theme}/img/waypoints.svg`,
  });



  ngOnInit() {
    this.orderService.expansionOpened$
      .pipe(
        tap((state) => {
          if(state === 'opened') {
            const container = document.querySelector('.drawer-info-container') as HTMLElement;
            if (container) {
              container.style.overflow = 'auto';
            }
            const el = document.querySelector('.mtx-drawer-content-wrapper') as HTMLElement;
            el.style.height = 'fit-content';
            el.style.maxHeight = '90vh';
          } else if (state === 'closed') {
            setTimeout(() => {
              const container = document.querySelector('.drawer-info-container') as HTMLElement;
              if(container) {
                container.style.overflow = 'hidden';
              }
              const el = document.querySelector('.mtx-drawer-content-wrapper') as HTMLElement;
              const alwaysView = document.querySelector('.always-view') as HTMLElement;
              if(el && alwaysView) {
                el.style.height = `calc(${alwaysView.offsetHeight}px + 25px)`;
                document.getElementById('map').style.height = `calc(100vh - ${el.style.height + 16})`
              }
            }, 150)
          } else {
            const container = document.querySelector('.drawer-info-container') as HTMLElement;
            if (container) {
              container.style.overflow = 'auto';
            }
            document.getElementById('map').style.height = `100vh`
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe()
    this.breakpointObserver
      .observe('(max-width: 750px)')
      .pipe(
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(result => {
        if (result.matches) {
          this.drawerPosition = 'bottom';
        } else {
          this.drawerPosition = 'left';
        }
        this.open();
      });
  }

  ngAfterViewInit() {
    this.initializeMap();
  }


  private initializeMap() {
    // const baseMapURl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    // this.map = L.map('map');
    // L.tileLayer(baseMapURl).addTo(this.map);
    let tileLayer;
      tileLayer = L.gridLayer.googleMutant({
        type: 'roadmap',
        attribution: 'UTAX',
        styles: this.systemThemeDetectService.theme === 'dark' ? DARK_STYLES_MAP : []
      });

    this.map = L.map(this.mapEl.nativeElement);
    this.map.invalidateSize();
    tileLayer.addTo(this.map);

    this.orderService.currentOrder$
      .pipe(
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((order) => {
      if (order) {
        const markers = order.addresses.sort((a, b) => a.order - b.order).map((address) => {
          const marker = L.marker([address.lat, address.lon], {
            title: address.order + 1 + ') ' + address.name,
            icon: address.type === 'start' ?
              this.startIcon : address.type === 'end' ?
                L.divIcon({iconSize: null, className: '', html: this.createPointTemplate(order.addresses.length )}) :
                this.waypointIcon
          });
          if (address.type === 'start') {
            this.passengerMarker = marker;
          }
          return marker;
        });
        this.driverMarker = this.driverMarker || L.marker([order.cab_latitude, order.cab_longitude], {icon: this.carIcon})
        this.markers = [
          ...markers,
          this.driverMarker
        ];
        // this.centerMap();
        if([...this.arrivingStatuses, ...this.waitingStatuses].includes(order.trip_status)) {
          let cabLocation = this.machine ? {...this.machine.getWaypoints()[0].latLng} : {lat: order.cab_latitude, lng: order.cab_longitude};
          if(this.machine) {
            this.machine.remove();
            this.machine = null;
          }
          const waypoints = [
            L.latLng(cabLocation.lat, cabLocation.lng),
            L.latLng(order.addresses[0].lat, order.addresses[0].lon)
          ];
            this.machine = L.Routing.control({
              show: false,
              waypointMode: 'snap',
              waypoints,
              fitSelectedRoutes: true,
              plan: L.Routing.plan(waypoints, {
                createMarker: (i, wp) => {
                  return L.marker(wp.latLng, {
                    icon: i === 0 ? this.carIcon : this.startIcon,
                    draggable: false
                  });
                }
              }),
              addWaypoints: false,
              routeWhileDragging: false,
              lineOptions: {
                extendToWaypoints: false,
                missingRouteTolerance: 0,
                styles: [
                  {color: this.systemThemeDetectService.theme === 'dark' ? '#FFCB01' : '#4D535F'}
                ]
              },
              router: L.Routing.osrmv1({
                serviceUrl: 'https://rm.utaxcloud.net/route/v1'
              })
            }).addTo(this.map)

          this.machine?.on('routesfound', (e) => {
            const routes = e.routes;
            let duration = routes[0].summary.totalTime;
            this.orderService.arrivingTimer$.next(duration);
          });
          this.map.invalidateSize();
        } else if (this.progressStatuses.includes(order.trip_status)) {
          let cabLocation = this.machine ? {...this.machine.getWaypoints()[0].latLng} : {lat: order.cab_latitude, lng: order.cab_longitude};
          if(this.machine){
            this.machine.remove();
            this.machine = null;
          }
          const waypoints = [L.latLng(cabLocation.lat, cabLocation.lng)].concat(order.addresses.slice(-1).map((address) => L.latLng(address.lat, address.lon)));
          this.machine = L.Routing.control({
            show: false,
            waypointMode: 'snap',
            fitSelectedRoutes: true,
            waypoints,
            plan: L.Routing.plan(waypoints, {
              createMarker: (i, wp) => {
                return L.marker(wp.latLng, {
                  icon: i === 0 ?
                    this.carIcon : i === waypoints.length - 1 ?
                      L.divIcon({iconSize: null, className: '', html: this.createPointTemplate(order.addresses.length )}) : this.waypointIcon,
                  draggable: false
                });
              }
            }),
            addWaypoints: false,
            routeWhileDragging: false,
            lineOptions: {
              extendToWaypoints: false,
              missingRouteTolerance: 0,
              styles: [
                {color: this.systemThemeDetectService.theme === 'dark' ? '#FFCB01' : '#4D535F'}
              ]
            },
            router: L.Routing.osrmv1({
              serviceUrl: 'https://rm.utaxcloud.net/route/v1'
            })
          }).addTo(this.map)

          // this.driverMarker.addTo(this.map);

          this.machine?.on('routesfound', (e) => {
            const routes = e.routes;
            let duration = routes[0].summary.totalTime;
            this.orderService.arrivingTimer$.next(duration);
          });
          this.map.invalidateSize();
        } else {
          if(this.machine){
            this.machine.remove();
            this.machine = null;
          }
        }
      }
    });
    this.getRealTimeLocation();
    this.cdr.detectChanges();
  }


  // private addMarkers() {
  //   // Add your markers to the map
  //   this.markers.forEach(marker => marker.addTo(this.map));
  // }

  private centerMap() {
    // Create a LatLngBounds object to encompass all the marker locations
    const bounds = L.latLngBounds(this.markers.map(marker => marker.getLatLng()));

    // Fit the map view to the bounds
    this.map.fitBounds(bounds);
  }

  // private passengerLocationTimer() {
  //   if (this.passengerTimer) {
  //     clearTimeout(this.passengerTimer);
  //   }
  //   this.passengerTimer = setTimeout(() => {
  //     this.orderService.passengerLocation$.next(null);
  //   }, 20000);
  // }
  //
  // private driverLocationTimer() {
  //   if (this.driverTimer) {
  //     clearTimeout(this.driverTimer);
  //   }
  //   this.driverTimer = setTimeout(() => {
  //     this.orderService.driverLocation$.next(null);
  //   }, 20000);
  // }


  private getRealTimeLocation() {
    // this.orderService.passengerLocation$
    //   .pipe(
    //     tap((location) => {
    //       if(this.orderService.currentOrder$.value && location) {
    //         if(this.orderService.currentOrder$.value.status === 'arriving') {
    //           const locationArr = location.split(',');
    //           const points  = this.machine.getWaypoints()
    //             .map((point, index) => {
    //               if(index === 1) {
    //                 point.latLng = L.latLng(+locationArr[0], +locationArr[1])
    //                 return point
    //               }
    //               return point
    //             })
    //           this.machine.setWaypoints(points);
    //         } else {
    //           if (location) {
    //             const locationArr = location.split(',');
    //             if (!this.passengerMarker) {
    //               this.passengerMarker = createMarker(
    //                 {lat: locationArr[0], lng: locationArr[1]},
    //                 this.map,
    //                 `assets/custom/${this.systemThemeDetectService.theme}/img/start.svg`,
    //                 // this.createPointTemplateLocation(),
    //                 'html',
    //                 375
    //               );
    //             } else {
    //               this.passengerMarker.setLatLng(locationArr).update();
    //             }
    //             // this.passengerLocationTimer();
    //           } else {
    //             if (this.passengerMarker) {
    //               this.map.removeLayer(this.passengerMarker);
    //               this.passengerMarker = null;
    //             }
    //         }
    //       }
    //
    //       }
    //     }),
    //     takeUntilDestroyed(this.destroyRef)
    //   )
    //   .subscribe();

    this.orderService.driverLocation$
      .pipe(
        tap((location) => {
          if(!this.orderService.currentOrder$.value || !location || this.completedStatuses.includes(this.orderService.currentOrder$.value.trip_status)) {
            return;
          } else {
            const locationArr = location.split(',');
            // if (this.progressStatuses.includes(this.orderService.currentOrder$.value.trip_status)) {
              //     if (!this.driverMarker) {
              //       this.driverMarker = createMarker(
              //         {lat: locationArr[0], lng: locationArr[1]},
              //         this.map,
              //         `assets/custom/${this.systemThemeDetectService.theme}/img/car.svg`,
              //         // this.cabMarkerTemplate(),
              //         'icon'
              //       );
              //     } else {
                    this.driverMarker.setLatLng(locationArr)
                      // .update();
              //     }
            // } else {
              const points  = this.machine.getWaypoints()
                .map((point, index) => {
                  if(index === 0) {
                    point.latLng = L.latLng(+locationArr[0], +locationArr[1])
                    return point
                  }
                  return point
                })
              this.machine.setWaypoints(points);
            // }





            // else {
            //   if (location) {
            //     this.machine?.remove();
            //     const locationArr = location.split(',');
            //     if (!this.driverMarker) {
            //       this.driverMarker = createMarker(
            //         {lat: locationArr[0], lng: locationArr[1]},
            //         this.map,
            //         `assets/custom/${this.systemThemeDetectService.theme}/img/car.svg`,
            //         // this.cabMarkerTemplate(),
            //         'icon'
            //       );
            //     } else {
            //       this.driverMarker.setLatLng(locationArr).update();
            //     }
            //     // this.driverLocationTimer();
            //   } else {
            //     if (this.driverMarker) {
            //       this.map.removeLayer(this.driverMarker);
            //       this.driverMarker = null;
            //     }
            //   }
            // }
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  createPointTemplate(lastPoint: number) {
    return `<div class="map-marker">
        <div class="last-point finish-icon">${lastPoint}</div>
        ${this.systemThemeDetectService.theme === 'dark' ? DARK_END_ICON : LIGHT_END_ICON}
    </div>`;
  }


  open() {
    const drawerRef = this.drawer.open(DrawerInfoComponent, {
      panelClass: 'drawer-info-panel',
      position: this.drawerPosition,
      width: 375,
      height: 192,
      hasBackdrop: false,
      disableClose: true,
      closeOnNavigation: false,
      data: {},
    });
    drawerRef.afterOpened()
      .pipe(
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
      this.orderService.expansionOpened$.next(this.drawerPosition === 'bottom' ? 'closed' : null);
    });
  }
}
