<div #map id="map"></div>


@if(websocketService.errorCounter) {
  <app-link-not-active class="not-active"></app-link-not-active>
} @else {
  @if(orderService.currentOrder$ | async; as order) {
    @if (!order.trip_status || order.trip_status === 'canceled' || order.trip_status === 'completed') {
      <app-link-not-active class="not-active"></app-link-not-active>
    }
  }
}

