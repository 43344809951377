<div class="link-not-active-container background-main">
  <div class="link-not-active-main-block">
    <svg width="79" height="174" viewBox="0 0 79 174" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M44.0493 54.4665C44.0493 54.4665 47.9189 31.2784 51.3244 20.3767C50.798 24.0619 51.286 24.5097 51.286 24.5097C51.286 24.5097 52.5919 17.7738 53.7275 15.7958C52.6638 19.9058 52.8125 20.6922 52.8125 20.6922C52.8125 20.6922 54.2899 15.7485 55.1544 14.9088C54.4613 16.8394 54.435 17.3515 54.435 17.3515C54.435 17.3515 55.17 15.4512 57.7721 13.8021C61.27 11.5875 65.5053 15.2801 68.8365 26.143C70.4733 31.4823 63.2618 50.9244 62.8193 57.3024C62.324 58.5292 44.0469 54.4665 44.0469 54.4665" fill="#FFF9F2"/>
      <path d="M46.5691 59.7211C46.5691 59.7211 43.9946 44.7494 52.0851 22.5066C56.3732 10.6971 64.7563 1.19692 71.5842 0.648438C69.4497 2.05242 69.3442 3.30107 69.3442 3.30107C69.3442 3.30107 73.3421 1.40443 74.7247 2.31817C72.2125 2.95524 71.83 3.75976 71.83 3.75976C71.83 3.75976 74.9082 3.38359 75.6025 4.51696C74.3686 4.43809 74.0856 4.73539 74.0856 4.73539C74.0856 4.73539 75.3159 4.91741 76.9299 8.91456C79.1003 14.2854 78.2897 24.9978 73.3589 38.6784C70.9354 45.4035 63.3893 58.9324 59.8255 62.2779C59.0257 62.1189 46.5691 59.7175 46.5691 59.7175" fill="#FFF9F2"/>
      <path d="M69.0899 41.191C62.123 59.774 55.216 73.8672 51.1869 73.5335C47.1578 73.1998 48.4457 59.5702 51.8476 39.8125C54.9965 21.5584 63.46 8.37042 70.0301 7.71393C74.8374 7.22855 75.8471 23.1613 69.0863 41.191" fill="#EDA6B0"/>
      <path d="M36.498 53.3356C36.498 53.3356 32.2423 30.213 28.6593 19.3731C29.2469 23.0499 28.7672 23.5061 28.7672 23.5061C28.7672 23.5061 27.3487 16.792 26.1807 14.8347C27.3115 18.9265 27.1772 19.7153 27.1772 19.7153C27.1772 19.7153 25.6183 14.7983 24.7394 13.972C25.4588 15.8904 25.4936 16.3989 25.4936 16.3989C25.4936 16.3989 24.7274 14.5107 22.0977 12.9077C18.5626 10.7563 14.3897 14.5204 11.2407 25.438C9.69146 30.8064 17.2256 50.1223 17.7736 56.4906C18.2904 57.7101 36.498 53.3356 36.498 53.3356Z" fill="#FFF9F2"/>
      <path d="M34.052 58.6371C34.052 58.6371 36.3771 43.6241 27.9232 21.5232C23.4373 9.78781 14.8983 0.433207 8.06081 0C10.2193 1.36758 10.3392 2.6138 10.3392 2.6138C10.3392 2.6138 6.31008 0.786326 4.94307 1.72312C7.46724 2.31772 7.86296 3.11982 7.86296 3.11982C7.86296 3.11982 4.78359 2.79097 4.10848 3.94012C5.34118 3.84062 5.63257 4.13306 5.63257 4.13306C5.63257 4.13306 4.40226 4.33571 2.85419 8.35956C0.773692 13.768 1.76298 24.4647 6.92044 38.0591C9.45541 44.7417 17.2246 58.1396 20.8436 61.4244C21.641 61.2521 34.0556 58.6334 34.0556 58.6334" fill="#FFF9F2"/>
      <path d="M11.2429 40.4951C18.518 58.9567 25.6576 72.9298 29.6819 72.5306C33.7062 72.1313 32.2001 58.5223 28.46 38.8266C25.0077 20.6294 16.326 7.58833 9.74276 7.03863C4.92705 6.63819 4.18359 22.5831 11.2429 40.4939" fill="#EDA6B0"/>
      <path d="M14.4717 61.8909C20.4158 54.2085 28.6215 47.8596 39.6679 46.0625C36.2072 47.225 36.0297 47.8572 36.0297 47.8572C36.0297 47.8572 42.505 46.037 44.7414 46.1729C40.6715 47.0466 40.048 47.5272 40.048 47.5272C40.048 47.5272 45.0304 46.6535 46.1492 47.0503C44.1514 47.293 43.6945 47.4968 43.6945 47.4968C43.6945 47.4968 45.6887 47.3099 48.2764 48.9008C51.7623 51.0341 50.4013 56.4401 42.3611 64.2172C38.4088 68.0384 26.1429 76.3251 20.357 78.752C19.0632 78.854 4.57767 74.6724 14.4717 61.8849" fill="#FFF9F2"/>
      <path d="M78.8505 122.765C76.6034 78.8007 67.099 47.9617 38.2935 47.4375C30.432 47.7421 24.0502 50.3838 18.9143 54.9792C5.51643 66.9694 0.599986 92.2641 0.0184076 124.084C-0.341332 143.642 4.55832 170.03 15.8973 173.532C26.1211 176.687 28.2484 162.833 40.1845 162.548C52.1279 162.427 54.5969 176.214 64.7116 172.716C75.9295 168.833 79.8494 142.301 78.8505 122.765Z" fill="#FFF9F2"/>
      <g clip-path="url(#clip0_17476_59006)">
        <rect x="17.8125" y="89.6406" width="17.4806" height="32.2601" rx="2.77966" transform="rotate(-13.1752 17.8125 89.6406)" fill="#565150"/>
        <rect x="20.6562" y="91.5" width="9.01652" height="8.94043" rx="0.926554" transform="rotate(-13.1752 20.6562 91.5)" fill="#423D3C"/>
      </g>
      <path d="M46.9707 64.3082C48.3053 60.9772 53.181 59.6145 54.8262 63.5109C54.9713 63.8543 55.4725 63.5655 55.3286 63.2245C54.9404 62.323 54.2732 61.5736 53.4276 61.089C52.582 60.6045 51.6037 60.4111 50.64 60.5379C48.6423 60.8061 47.1421 62.3338 46.4167 64.1468C46.2776 64.4938 46.834 64.6516 46.9707 64.3082Z" fill="#565150"/>
      <path d="M31.3681 64.7685C29.9291 61.4837 25.0127 60.2848 23.491 64.2346C23.3567 64.5817 22.8459 64.3099 22.979 63.964C23.3377 63.0491 23.9808 62.2771 24.8113 61.7646C25.6417 61.2522 26.6144 61.0271 27.5824 61.1233C29.5886 61.3235 31.1379 62.8003 31.9233 64.589C32.0732 64.9312 31.5228 65.1071 31.3741 64.7685" fill="#565150"/>
      <path d="M57.2693 69.2636C57.3761 70.6785 55.4706 71.9793 53.0016 72.1686C50.5326 72.3579 48.4521 71.3653 48.3442 69.9504C48.2363 68.5355 50.1429 67.2359 52.6119 67.0466C55.0809 66.8573 57.1614 67.8499 57.2693 69.2636Z" fill="#F0BBBE"/>
      <path d="M20.6961 69.4575C20.6494 70.8736 22.6147 72.1271 25.0862 72.2654C27.5576 72.4038 29.5985 71.3614 29.6429 69.9453C29.6872 68.5292 27.7242 67.2757 25.2528 67.1373C22.7814 66.999 20.7405 68.0414 20.6961 69.4575Z" fill="#F0BBBE"/>
      <path d="M44.6699 75.2633C44.592 75.2013 44.4978 75.1638 44.3989 75.1554C44.1591 75.1202 43.8881 75.1857 43.8066 75.2985C43.7922 75.3167 43.43 75.7633 42.0966 75.7839C40.9958 75.8021 40.0665 75.6322 39.7523 75.4624C39.7378 75.4555 39.7241 75.4469 39.7115 75.4369C39.7143 75.3478 39.6834 75.261 39.6252 75.1942C39.552 75.1469 39.4429 74.9976 39.3278 75C39.2678 75 39.2079 75 39.1467 75C39.0268 75 38.9237 75.1554 38.8541 75.2039C38.8268 75.228 38.8055 75.2582 38.7917 75.2921C38.778 75.326 38.7723 75.3627 38.775 75.3993C38.7678 75.3993 38.7558 75.4417 38.7354 75.4539C38.4273 75.6335 37.5039 75.8482 36.4031 75.8664C35.0541 75.8895 34.6944 75.4636 34.68 75.4466C34.6158 75.38 34.5313 75.3373 34.4401 75.3252C34.3212 75.3034 34.1993 75.3034 34.0804 75.3252C33.8406 75.3689 33.7099 75.4976 33.7962 75.6092C33.7962 75.6165 34.3802 76.3506 36.4103 76.3179C37.6982 76.296 38.7138 76.0752 39.2547 75.8495C39.8015 76.063 40.8243 76.2438 42.111 76.222C44.1411 76.188 44.6951 75.4357 44.6987 75.4296C44.7089 75.4175 44.7164 75.4033 44.7209 75.3881C44.7253 75.3728 44.7266 75.3568 44.7245 75.341C44.7225 75.3253 44.7172 75.3101 44.7091 75.2965C44.7009 75.2829 44.69 75.2712 44.6771 75.2621" fill="#565150"/>
      <path d="M41.3517 70.2725C40.8097 71.1717 39.1369 71.9131 39.1369 71.9131C39.1369 71.9131 37.4702 71.2275 36.8694 70.3477C36.2986 69.5104 37.8935 68.9183 39.131 68.8916C40.3685 68.8649 41.8698 69.4073 41.3517 70.2664" fill="#565150"/>
      <path d="M39.6664 71.4444L38.6797 71.4609L38.7478 75.6201L39.7346 75.6036L39.6664 71.4444Z" fill="#565150"/>
      <path d="M53.9301 115.645C51.8467 114.594 50.2957 113.141 49.113 111.769C48.1905 110.696 47.7809 109.316 47.9303 108.261C48.04 107.46 48.5257 106.767 49.3126 106.302C50.1818 105.791 51.3334 105.613 52.4362 105.468L52.6145 105.432C54.202 105.222 63.2445 104.253 70.8866 102.714C67.485 102.998 64.0833 103.303 60.6867 103.642C57.9446 103.914 55.1888 104.151 52.4604 104.511C51.2331 104.672 49.9164 104.821 48.8422 105.454C46.255 106.98 46.7092 110.014 48.525 112.129C51.0862 115.108 55.195 116.917 58.8368 118.009C60.7687 118.589 62.7088 119.227 64.7747 119.466C60.7319 118.43 56.0395 116.71 53.9263 115.644" fill="#C7CBCE"/>
      <path d="M72.0889 115.048C73.8137 111.032 75.8192 104.986 77.0086 101.102C74.9969 101.748 72.953 102.286 70.8859 102.712C63.2443 104.25 54.2044 105.214 52.6185 105.421L52.4469 105.443C51.3451 105.588 50.2068 105.735 49.3372 106.249C48.5492 106.714 48.0936 107.354 47.978 108.159C47.8275 109.214 48.1857 110.704 49.1082 111.777C50.2893 113.152 51.842 114.601 53.9253 115.652C56.0385 116.718 60.7325 118.434 64.7737 119.474C67.1027 120.07 68.2594 120.229 69.543 120.114C70.1775 119.012 71.6021 116.168 72.084 115.047" fill="#FFF9F2"/>
      <g opacity="0.25">
        <path d="M48.7826 108.105L50.5913 108.257C51.1941 108.312 51.7967 108.355 52.4023 108.367C51.8007 108.292 51.197 108.247 50.5936 108.215L48.7826 108.105Z" fill="#565150"/>
      </g>
      <g opacity="0.25">
        <path d="M48.2825 111.143L51.1111 111.52L52.5225 111.701C52.9946 111.75 53.4669 111.81 53.9414 111.852C53.472 111.774 53.0003 111.715 52.5294 111.647L51.1128 111.474L48.2786 111.14L48.2825 111.143Z" fill="#565150"/>
      </g>
      <path d="M22.5188 102.798C24.7876 102.252 26.909 102.352 28.6992 102.636C30.0962 102.857 31.3 103.64 31.8697 104.545C32.3002 105.227 32.3818 106.07 32.0814 106.933C31.7501 107.885 30.987 108.767 30.2398 109.591L30.127 109.734C29.0529 110.92 22.7841 117.514 17.9509 123.634C20.3596 121.211 22.7584 118.782 25.1281 116.319C27.0423 114.336 28.9906 112.372 30.8395 110.332C31.6705 109.414 32.5779 108.449 32.986 107.271C33.9715 104.433 31.6682 102.413 28.9099 101.976C25.0324 101.362 20.7283 102.643 17.2449 104.168C15.3968 104.98 13.5044 105.746 11.7722 106.902C15.5287 105.074 20.2153 103.348 22.5165 102.793" fill="#C7CBCE"/>
      <path d="M9.07345 115.003C10.3562 119.189 13.7725 121.766 15.3787 125.497C16.4925 123.701 16.6635 125.294 17.9633 123.632C22.7967 117.513 29.0672 110.926 30.1411 109.739L30.2575 109.611C31.0049 108.788 31.7762 107.935 32.1083 106.982C32.4076 106.119 32.3412 105.336 31.9107 104.653C31.343 103.752 30.1068 102.848 28.7098 102.627C26.917 102.342 24.7982 102.243 22.5294 102.789C20.2283 103.343 15.5384 105.071 11.7851 106.897C9.62429 107.95 8.63331 108.578 7.73453 109.496C7.9634 110.746 8.71662 113.837 9.07462 115.003" fill="#FFF9F2"/>
      <g opacity="0.25">
        <path d="M31.3341 105.219L29.8827 106.312C29.4009 106.676 28.9144 107.041 28.4073 107.366C28.8759 106.983 29.3641 106.625 29.8586 106.273L31.3341 105.219Z" fill="#C7CBCE"/>
      </g>
      <g opacity="0.25">
        <path d="M29.7535 102.598L27.3751 104.178L26.1827 104.959C25.779 105.211 25.3811 105.471 24.9734 105.717C25.3619 105.442 25.7598 105.182 26.1531 104.913L27.3507 104.137L29.7524 102.593L29.7535 102.598Z" fill="#C7CBCE"/>
      </g>
      <defs>
        <clipPath id="clip0_17476_59006">
          <rect x="17.8125" y="89.6406" width="17.4806" height="32.2601" rx="2.77966" transform="rotate(-13.1752 17.8125 89.6406)" fill="white"/>
        </clipPath>
      </defs>
    </svg>
    <div class="link-not-active-content-text">
      <div class="link-not-active-content-text-main main-text-color">{{'ERROR.OLD_LINK.TITLE' | translate}}</div>
      <div class="link-not-active-content-text-sub main-subtext-color">{{'ERROR.OLD_LINK.TEXT' | translate}}</div>
    </div>
    <div class="link-not-active-market-link">
      @if (deviceInfoService.currentDevice === 'desktop') {
        <img [src]="'./assets/custom/' + systemThemeDetectService.theme + '/img/ios.svg'"
             (click)="openLink('https://apps.apple.com/ua/app/taxi-838-%D0%B7%D0%B0%D0%BC%D0%BE%D0%B2-%D1%82%D0%B0%D0%BA%D1%81%D1%96-%D0%BE%D0%BD%D0%BB%D0%B0%D0%B9%D0%BD/id1148856166')">
        <img [src]="'./assets/custom/' + systemThemeDetectService.theme + '/img/android.svg'"
             (click)="openLink('https://play.google.com/store/apps/details?id=com.mobox.taxi')">
        <img [src]="'./assets/custom/' + systemThemeDetectService.theme + '/img/huawei.svg'"
             (click)="openLink('https://appgallery.huawei.com/#/app/C102506291')">
      } @else if(deviceInfoService.currentDevice === 'android') {
        <img [src]="'/assets/custom/' + systemThemeDetectService.theme + '/img/' + deviceInfoService.currentDevice + '.svg'"
             (click)="openLink('https://play.google.com/store/apps/details?id=com.mobox.taxi')">
      } @else if(deviceInfoService.currentDevice === 'ios') {
        <img [src]="'/assets/custom/' + systemThemeDetectService.theme + '/img/' + deviceInfoService.currentDevice + '.svg'"
             (click)="openLink('https://apps.apple.com/ua/app/taxi-838-%D0%B7%D0%B0%D0%BC%D0%BE%D0%B2-%D1%82%D0%B0%D0%BA%D1%81%D1%96-%D0%BE%D0%BD%D0%BB%D0%B0%D0%B9%D0%BD/id1148856166')">
      } @else if(deviceInfoService.currentDevice === 'huawei') {
        <img [src]="'/assets/custom/' + systemThemeDetectService.theme + '/img/' + deviceInfoService.currentDevice + '.svg'"
             (click)="openLink('https://appgallery.huawei.com/#/app/C102506291')">
      }
    </div>
  </div>
  <div class="link-not-active-footer background-sub">
    <svg width="90" height="20" viewBox="0 0 90 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_19972_549)">
        <path d="M59.2154 9.32173C59.9478 8.83558 60.521 8.24754 60.9244 7.56396C61.3765 6.79759 61.6058 5.93569 61.6037 5.00373C61.6016 3.68965 61.1048 2.46685 60.2089 1.56249C59.1942 0.534999 57.757 -0.00422094 56.048 2.48812e-05C54.4261 0.0042707 52.9443 0.539244 51.8744 1.50729C51.3394 1.99132 50.9233 2.56875 50.6367 3.22048C50.3438 3.8892 50.1952 4.6216 50.1973 5.39434C50.1973 6.29658 50.4499 7.17334 50.9191 7.9291C51.3161 8.56385 51.8553 9.10944 52.5304 9.55313C51.7024 10.0329 51.038 10.621 50.5476 11.3109C49.8873 12.2365 49.554 13.3255 49.5562 14.5483C49.5604 16.0259 50.1633 17.3867 51.2545 18.3823C51.8171 18.8961 52.49 19.2952 53.2564 19.5711C54.0546 19.8577 54.9377 20.0021 55.8803 20C57.636 19.9957 59.2473 19.4183 60.4149 18.3717C61.0008 17.8473 61.4593 17.2232 61.7735 16.5163C62.0983 15.7881 62.2618 14.992 62.2597 14.1492C62.2575 12.9625 61.9115 11.9032 61.2301 11.0031C60.7248 10.3344 60.0497 9.77179 59.2154 9.31961V9.32173ZM55.9207 2.7492C58.1816 2.74495 58.3004 4.84451 58.3026 5.26485C58.3047 6.52373 57.5426 7.5491 56.1988 8.09893C54.3179 7.52787 53.4729 6.63412 53.4708 5.22026C53.4687 3.99322 54.2244 2.75344 55.9207 2.7492ZM55.927 17.2253C55.1246 17.2253 54.4261 16.9727 53.9039 16.4908C53.3031 15.9346 52.9783 15.1067 53.0144 14.2193V14.2002C53.0123 12.6674 53.94 11.4765 55.5725 10.9097C57.8759 11.64 58.8227 12.6632 58.8269 14.4146C58.8312 16.0917 57.6657 17.2211 55.927 17.2253Z" fill="#FFCC00"/>
        <path d="M72.445 9.41726C72.2688 9.31748 72.0862 9.2262 71.8994 9.14553C72.5511 8.75491 73.0861 8.26877 73.4937 7.69133C74.0754 6.87189 74.3683 5.9187 74.3662 4.85937C74.3641 3.56227 73.8886 2.37344 73.0309 1.50942C72.0501 0.518016 70.6172 -0.00422014 68.8912 2.56841e-05C66.8851 0.00427151 65.0084 0.781257 64.0361 1.54763L63.7771 1.75143L64.7303 4.56429L65.3566 4.08451C66.0211 3.57501 67.2311 2.97635 68.4475 2.97422C70.6872 2.96998 70.9335 4.57278 70.9356 5.26272C70.9398 7.1967 68.9655 7.9015 67.6387 7.90575L65.8024 7.90999L65.8088 10.742L67.6451 10.7377C69.5196 10.7335 71.5343 11.5699 71.564 13.9348C71.5725 14.3658 71.5003 15.4442 70.6426 16.2276C70.0652 16.7562 69.2479 17.0237 68.2161 17.0258C66.6855 17.03 65.2632 16.338 64.7261 15.9707L64.1041 15.5461L63.1509 18.4587L63.4396 18.6604C64.327 19.2803 66.1208 20.0042 68.2501 19.9979C70.3433 19.9936 72.099 19.3631 73.3281 18.1764C74.4426 17.098 75.0562 15.6077 75.0519 13.9773C75.0498 12.6526 74.6188 11.4574 73.8058 10.5233C73.4236 10.0839 72.9672 9.71235 72.4471 9.41514L72.445 9.41726Z" fill="#FFCC00"/>
        <path d="M88.2676 11.0052C87.7623 10.3365 87.0872 9.77391 86.2529 9.32173C86.9853 8.83558 87.5585 8.24754 87.9619 7.56396C88.4141 6.79759 88.6433 5.93569 88.6412 5.00373C88.6391 3.68965 88.1423 2.46685 87.2465 1.56249C86.2317 0.534999 84.7924 -0.00422094 83.0834 2.48812e-05C81.4615 0.0042707 79.9797 0.539244 78.9098 1.50729C78.3748 1.99132 77.9587 2.56875 77.6721 3.22048C77.3792 3.8892 77.2306 4.6216 77.2327 5.39434C77.2327 6.29658 77.4853 7.17334 77.9545 7.9291C78.3515 8.56385 78.8907 9.10944 79.5658 9.55313C78.7378 10.0329 78.0734 10.621 77.583 11.3109C76.9227 12.2365 76.5894 13.3255 76.5916 14.5483C76.5958 16.0259 77.1987 17.3867 78.2899 18.3823C78.8525 18.8961 79.5254 19.2952 80.2918 19.5711C81.09 19.8577 81.9731 20.0021 82.9157 20C84.6714 19.9957 86.2827 19.4183 87.4503 18.3717C88.0362 17.8473 88.4947 17.2232 88.8089 16.5163C89.1337 15.7881 89.2972 14.992 89.2951 14.1492C89.2929 12.9625 88.9469 11.9032 88.2655 11.0031L88.2676 11.0052ZM82.9582 2.7492C85.2191 2.74495 85.338 4.84451 85.3401 5.26485C85.3422 6.52373 84.5801 7.5491 83.2363 8.09893C81.3554 7.52787 80.5105 6.63412 80.5083 5.22026C80.5062 3.99322 81.262 2.75344 82.9582 2.7492ZM82.9645 17.2253C82.1621 17.2253 81.4636 16.9727 80.9414 16.4908C80.3406 15.9346 80.0158 15.1067 80.0519 14.2193V14.2002C80.0498 12.6674 80.9775 11.4765 82.61 10.9097C84.9134 11.64 85.8602 12.6632 85.8644 14.4146C85.8687 16.0917 84.7032 17.2211 82.9645 17.2253Z" fill="#FFCC00"/>
        <path d="M43.493 3.47815H40.6504V16.551H43.493V3.47815Z" fill="#008DFF"/>
        <path d="M37.1447 16.5408H34.1684L31.2748 12.0508L31.2472 12.0084C31.2472 12.0084 29.5234 15.1269 28.6658 16.5556H25.6194C25.9421 16.0164 28.7825 11.5052 29.6911 10.0234L29.7017 10.0065L29.6911 9.98736C29.0012 8.86434 26.0758 4.02834 25.7383 3.4679H28.6509C28.8144 3.72264 30.8821 6.9155 31.4107 7.71797L31.4468 7.77104C31.4468 7.77104 33.6631 3.70778 33.7905 3.48276H36.701L32.901 9.59886L37.1447 16.5408Z" fill="#008DFF"/>
        <path d="M9.86092 3.47815V5.84307H6.34326V16.5489H3.51766V5.84307H0V3.47815H9.86092Z" fill="#008DFF"/>
        <path d="M18.952 3.47815H15.774C15.6148 3.87938 13.4833 9.74074 10.9167 16.551H13.9037C14.133 15.8717 14.4004 14.9673 14.687 13.9972C15.1711 14.0014 19.5485 13.9972 20.0177 14.0035C20.3212 14.9949 20.5951 15.8908 20.8223 16.5532H23.8092C21.3296 9.76621 19.1112 3.87938 18.952 3.48027V3.47815ZM15.377 11.7044C16.1242 9.38834 17.0923 6.53939 17.3258 6.07235C17.5424 6.53514 18.5507 9.41806 19.3001 11.6959C18.8161 11.6981 15.8398 11.7044 15.377 11.7044Z" fill="#008DFF"/>
      </g>
      <defs>
        <clipPath id="clip0_19972_549">
          <rect width="89.2951" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </div>
</div>
