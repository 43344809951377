import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import * as Sentry from "@sentry/angular";
import * as L from 'leaflet';
import {environment} from "./environments/environment";
L.Icon.Default.imagePath = 'assets/leaflet/';

// Sentry.init({
//   dsn: 'https://179fcb77722b44f0ab6aece455a5d7fc@crash.analytics.megakit.pro/46',
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   release: '1.0.0',
//   tracesSampleRate: 1.0,
//   environment: environment.production ? 'production' : 'development'
// });


bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
